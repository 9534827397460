@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: Roboto;
  background-image: url('assets/background_pattern_bright.png');
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  box-shadow: 0 0 0px 1000px white inset;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: rgba(245, 245, 245, 0.301);
}

*::-webkit-scrollbar-thumb {
  background-color: #000000f1;
}

.sizeMobile div input {
  font-size: 10pt !important;
}

body {
  min-height: 100vh;
  min-height: stretch;
  min-height: -webkit-stretch;
}
html {
  height: stretch;
  height: -webkit-fill-available;
}
