.card-element {
  height: 100px;
  width: 100px;
  color: black;
}

.checkout {
  margin-left: 15%;
  margin-top: 12vh;
  width: 70%;
}

.checkout_div {
  background-color: white;
  padding: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.checkout_registration {
  width: 100%;
}

.checkout_registration_div form {
  width: 100%;
}

.grid-item {
  width: 50%;
}

.checkout_dropdown {
  font-size: 16px;
  width: 100%;
  color: black;
  border: 1px solid gainsboro;
}

.css-1s2u09g-control {
  border: none !important;
  border-bottom: 1px solid gainsboro !important;
  height: 49px !important;
  border-radius: 0 !important;
}

.css-1pahdxg-control {
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 3px solid #5bc0de !important;
  box-shadow: none !important;
  height: 49px !important;
}

.css-1pahdxg-control:hover {
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 3px solid #5bc0de !important;
  box-shadow: none !important;
}

.products-info {
  display: flex;
  justify-content: center;
  margin-top: 7px;
  color: #5bc0de;
}

.products-info span {
  margin-left: 7px;
  margin-top: 1px;
  color: black;
}

.checkout_table_head {
  background-color: #5bc0de;
}

.total_row {
  font-weight: 600 !important;
}

.row_before_total {
  width: 100%;
  height: 40px !important;
}

.checkout_link {
  text-decoration: underline;
  color: #5bc0de;
  cursor: pointer;
}

.checkout_link:visited,
.checkout_link:hover,
.checkout_link:active {
  color: #0275d8;
}

@media only screen and (max-width: 1000px) {
  .grid-item {
    width: 100%;
  }

  .checkout {
    margin-left: 0;
    margin-top: 5vh;
    width: 100%;
  }
}
