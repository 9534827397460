.login-wrapper-desktop {
  width: 100%;
  height: 100%;
  text-align: center;
}
.login-wrapper-desktop-paper {
  width: 400px;
  margin: 30px;
  padding: 40px;
  text-align: center;
  display: inline-block;
}
.login-wrapper-mobile {
  padding: 40px;
  width: 100%;
}
